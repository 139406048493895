import React from 'react';
import { Link } from 'react-router-dom';

const ContactFormArea = () => {
   return (
      <>
         {/* <section className="contact-form-area gray-bg pt-100 pb-100">
            <div className="container">
               <div className="form-wrapper">
                  <div className="row align-items-center">
                     <div className="col-xl-8 col-lg-8">
                        <div className="section-title mb-55">
                           <p><span></span> Anything On your Mind</p>
                           <h1>Estimate Your Idea</h1>
                        </div>
                     </div>
                     <div className="col-xl-4 col-lg-3 d-none d-xl-block ">
                        <div className="section-link mb-80 text-end">
                           <Link to="https://wa.me/+5217711979737"
                           className="primary_btn btn-icon ml-0"><span>+</span>Agenda tu Cita</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section> */}
      </>
   );
};

export default ContactFormArea;