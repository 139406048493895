import React from 'react';

const ContactMap = () => {
    return (
        <>
            {/* <section className="map-area">

                    <div style={{ width: '100%' }}>
                        <iframe title="Contact" src="https://maps.app.goo.gl/b8QthQ3NtsaHRM6c9" style={{ width: '100%' }} height={600} allowFullScreen="" loading="lazy"></iframe>
                    </div>

            </section> */}
        </>
    );
};

export default ContactMap;